import React, { useEffect, useState } from "react";
import logo from "./images/zindecah_Logo.png";
import "./App.css";

function App() {
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [velocity, setVelocity] = useState({ dx: 5, dy: 4 });

  useEffect(() => {
    const moveLogo = () => {
      let newX = position.x + velocity.dx;
      let newY = position.y + velocity.dy;

      // Check for boundaries and reverse the direction if needed
      if (newX > window.innerWidth - 100 || newX < 0) {
        setVelocity((prev) => ({ ...prev, dx: -prev.dx }));
      }
      if (newY > window.innerHeight - 100 || newY < 0) {
        setVelocity((prev) => ({ ...prev, dy: -prev.dy }));
      }

      // Update position
      setPosition({ x: newX, y: newY });
    };

    const intervalId = setInterval(moveLogo, 50);

    return () => clearInterval(intervalId);
  }, [position, velocity]);

  return (
    <div className="App">
      <img
        src={logo}
        alt="Zindecah Logo"
        style={{
          position: "absolute",
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: "100px",
          height: "auto",
        }}
      />
    </div>
  );
}

export default App;
